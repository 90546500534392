$blackColor: #67615a;
$blueColor: #388cfd;
$darkBlueColor: #4c50ec; /* 044ed2 */
$logoBlue: #1762aa;
$darkGrayColor: #a6a4a2;
$borderColor: #ccc;
$grayColor: #f2f2f2;
$whiteColor: #fff;
$yellowColor: #ffb86c;
$yellowDarkColor: #ffbd76;
/* backgrouns */
$bgLight: $grayColor;
$bgGray: $darkGrayColor;
$bgWhite: $whiteColor;
$bgYellow: $yellowColor;
/* text */
$textBlack: $blackColor;
$textBlue: $blueColor;
$textDark: $darkGrayColor;
$textWhite: $whiteColor;
/* gradient */
$gradBlueStart: $blueColor;
$gradBlueEnd: $darkBlueColor;
$gradYellowStart: $yellowColor;
$gradYellowEnd: $yellowDarkColor;
/* icons */
$iconDark: $darkGrayColor;
$iconGray: #c7c7c7;
$iconWhite: $whiteColor;
$iconYellow: $yellowColor;
/* buttons */
$btnBlue: $blueColor;
$btnBlueActive: $darkBlueColor;
$btnWhite: $whiteColor;
/* shadow */
$shadowGray: rgba(103,97,90,0.05);

@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
}

/* Main Blocks Style */
*:hover {
  transition: all 0.25s ease 0s;
}
html {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
body {
  width: 100%;
  height: 100%;
  color: $blackColor;
  font-family: 'SourceSansPro';
  font-weight: 300;
}
#root {
  width: 100%;
  height: 100%;
}
#root > div {
  width: 100%;
  height: 100%;
}
#page {
  width: 100%;
  height: 100%;
}
.wrapper {
  width: 100%;
  height: 100%;
  font-family: 'SourceSansPro';
  font-size: 14px;
  font-weight: 300;
  background-color: $grayColor;
  overflow-y: scroll;
}
.wrapper::-webkit-scrollbar {
  width: 12px;
  background-color: $grayColor;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.25);
}
.wrapper::-webkit-scrollbar-thumb {
  width: 12px;
  background-color: $blueColor;
}

.height-100 {
  height: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
a:hover {
  text-decoration: none;
}

.table-info {
  background-color: $blueColor;
}
.table-info th {
  border-color: $blueColor;
  background-color: $blueColor;
  color: $textWhite;
}
.table thead th {
  border-bottom: 2px solid $blueColor;
}
.table-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table-btn-td .profile-icon-wrap {
  align-self: center;
  justify-self: center;
}

/* Loader */
.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.loader.show {
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.85);
  z-index: 10;
}
.loader.show ~ .wrapper {
  height: 100%;
  overflow: hidden;
}
.loader.hide {
  width: 0;
  height: 0;
  background-color: transparent;
  z-index: 0;
  overflow: hidden;
}
.loader-gif {
  width: 65px;
  height: 65px;
}

/* Alert */
.alert-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-wrap.show {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.65);
  z-index: 10;
}
.alert-wrap.show ~ .wrapper {
  height: 100%;
  overflow: hidden;
}
.alert-wrap.hide {
  width: 0;
  height: 0;
  background-color: transparent;
  z-index: 0;
  overflow: hidden;
}
.alert-card {
  width: 270px;
  padding: 1rem 0;
  box-shadow: 0 3px 10px rgba(103, 97, 90, 0.25)!important;
}
.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 270px;
}
.alert-info-title {
  font-family: 'SourceSansPro';
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.alert-info-text {
  margin: 8px 1rem 0 1rem;
  color: $textDark;
  font-family: 'SourceSansPro';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}
.alert-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}
.alert-buttons .btn {
  flex: 1;
}

.modal-card {
  max-height: calc(100% - 100px);
  min-width: 50%;
  padding: 2rem;
}
.modal-card-inner {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 4px;
}
.modal-card-inner::-webkit-scrollbar {
  width: 8px;
  background-color: $grayColor;
  border-radius: 4px;
}
.modal-card-inner::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 4px;
  background-color: $blueColor;
}


/* Buttons Style */
.btn {
  border-radius: 8px;
}
.btn:focus {
  box-shadow: none !important;
}
.btn-info {
  border-color: $btnBlue;
  background-color: $btnBlue;
}
.btn-info:not(:disabled):not(.disabled).active, 
.btn-info:not(:disabled):not(.disabled):active {
  border-color: $btnBlueActive;
  background-color: $btnBlueActive;
}
.btn-info:hover {
  border-color: $btnBlueActive;
  background-color: $btnBlueActive;
}
.btn-info:focus {
  border-color: $btnBlue;
  background-color: $btnBlue;
}
.btn-outline-info {
  border-color: $btnBlue;
  color: $btnBlue;
}
.btn-outline-info:not(:disabled):not(.disabled).active, 
.btn-outline-info:not(:disabled):not(.disabled):active {
  border-color: $btnBlueActive;
  color: $btnWhite;
}
.btn-outline-info:hover {
  border-color: $btnBlueActive;
  background-color: $btnBlueActive;
  color: $btnWhite;
}
.btn-outline-warning {
  color: $yellowColor;
  border-color: $yellowColor;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active, 
.show > .btn-outline-warning.dropdown-toggle {
  background-color: $yellowColor;
  border-color: $yellowColor;
}
.btn-outline-warning:hover {
  background-color: $yellowColor;
  border-color: $yellowColor;
}

/* Cards Style  */
.card {
  border-radius: 25px;
  border-width: 0;
  box-shadow: 0 3px 4px $shadowGray;
}
.card-body {
  padding: 1.75rem;
}
.card-gray {
  margin: 0 2rem 2rem 2rem;
  background-color: $grayColor;
}
.empty-card {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 2rem 0;
  padding: 1.5rem;
}
.empty-card-2 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.breadcrumb {
  border-radius: 0;
  background-color: transparent;
  padding: 0.5rem 2rem 1rem 2rem;
  margin: 0;
}
.breadcrumb-item-back {
  margin-right: 12px;
}
.breadcrumb-icon {
  width: 22px;
  height: 22px;
}
.breadcrumb-item {
  margin-top: 1px;
}

/* Forms Style */
.form {
  padding: 1rem 2rem;
}
.form-no-indent {
  padding: 1rem 0;
  width: 100%;
}
.table-form {
  overflow-x: auto;
}
.form-title {
  margin: 0.5rem 0;
  font-size: 16px;
  font-weight: 600;
}
.form-control {
  border-color: $borderColor;
  border-radius: 8px;
}
/* switch */
.switch-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  border-width: 0;
  background-color: transparent;
}
.switch .switch-inner {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 54px;
  height: 28px;
  border: 1px solid $darkGrayColor;
  border-radius: 18px;
  background-color: $darkGrayColor;
}
.switch .switch-inner::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  margin: 1px;
  border-radius: 12px;
  background-color: $grayColor;
}
.switch.active .switch-inner {
  border-color: $yellowColor;
  background-color: $yellowColor;
}
.switch.active .switch-inner::after {
  left: calc(100% - 28px);
}
.switch .switch-label p {
  margin: 0;
  margin-left: 8px;
  color: $blackColor;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

/* range */
.range-box {
  width: 200px;
  height: 140px;
  margin-bottom: 2rem;
  border-radius: 0;
  overflow: hidden;
}
.range-wrap {
  width: 100%;
  margin-bottom: 16px;
}
.range-value {
  margin: 2rem 0 0 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.range-value-label {
  margin-bottom: 2rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
}
.range-wrap .range-value-label {
  margin-bottom: 16px;
  color: $blackColor;
  font-size: 12px;
  line-height: 14px;
}
input[type="range"] {
  display: block;
  -webkit-appearance: none;
  background-color: #bdc3c7;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  margin: 0;
  outline: 0;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: $bgYellow;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: .3s ease-in-out;
}​
  input[type="range"]::-webkit-slider-thumb:hover {
    background-color: white;
    border: 2px solid $bgYellow;
  }
  input[type="range"]::-webkit-slider-thumb:active {
    transform: scale(1.2);
  }


/* Header Elements Style */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 90px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: $bgWhite;
  box-shadow: 0 3px 4px $shadowGray;
}
.header h1 {
  color: $blackColor;
  font-family: 'SourceSansPro';
  font-size: 26px;
  font-weight: 900;
  letter-spacing: -0.04rem;
}
.header h5 {
  color: $textBlack;
  font-size: 14px;
  font-weight: 300;
}
.header .logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background: $gradBlueStart;
}
.header .logo-wrap img {
  width: 48px;
  height: 48px;
}
.header .logo-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.header .logo-text img {
  height: 44px;
}

/* Page Body Style */
.content {
  width: 100%;
  padding-top: 90px;
}
.content-card {
  width: calc(100% - 300px);
  margin-left: 280px;
  margin-top: 40px !important;
  padding-left: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 6px 8px rgba(103, 97, 90, 0.15);
}
.content-card .card-body {
  padding: 0;
}

/* Left Menu */ 
.menu-fixed {
  position: fixed;
  top: 0;
  left: calc((100% - 1140px + 30px)/2);
  z-index: 8;
  padding-top: 110px;
}
.menu-fixed.active {
  height: 100%;
}
.menu-height {
  height: 100%;
}
.left-menu {
  position: relative;
  float: left;
  width: 300px;
  height: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: $gradBlueStart; /* Old browsers */
  background: -moz-linear-gradient(top, $gradBlueStart 0%, $gradBlueEnd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $gradBlueStart 0%, $gradBlueEnd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $gradBlueStart 0%, $gradBlueEnd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$gradBlueStart, endColorstr=$gradBlueEnd,GradientType=0 );
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.left-menu::-webkit-scrollbar { 
  width: 0; 
}
.left-menu .left-menu-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.left-menu .menu-top-wrap {
  width: 100%;
}
.left-menu .left-menu-control {
  width: 100%;
}
.left-menu .left-menu-control img {
  width: 18px;
  height: 18px;
}
.left-menu .left-menu-control .btn-icon {
  padding: 1.5rem 2rem;
  border-radius: 25px;
}
.left-menu .profile-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.left-menu .profile-img-wrap {
  position: relative;
  width: 104px;
}
.left-menu .profile-img {
  width: 104px;
  height: 104px;
  border-radius: 64px;
  border: 4px solid $bgWhite;
}
.profile-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 14px;
  background-color: $bgWhite;
}
.profile-icon1-wrap {
  position: absolute;
  bottom: 54px;
  right: -8px;
}
.profile-icon2-wrap {
  position: absolute;
  bottom: 10px;
  right: -4px;
}
.profile-icon3-wrap {
  position: absolute;
  bottom: 0px;
  right: 8px;
}
.profile-icon {
  width: 18px;
  height: 18px;
}
.left-menu .profile-divider {
  width: 6px;
  height: 6px;
  margin: 1rem 0;
  border-radius: 3px;
  background-color: $iconWhite;
}
.left-menu .profile-info {
  margin: 0;
  padding: 0 2rem;
  color: $textWhite;
  line-height: 18px;
  text-align: center;
}
.left-menu .menu-list {
  width: 100%;
}
.left-menu .menu-item {
  width: 100%;
  background-color: transparent;
}
.left-menu .menu-item .menu-item-text {
  margin: 0.25rem 0;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
}
.left-menu .menu-item:hover .menu-item-text {
  font-weight: 400;
}
.left-menu .menu-item.active .menu-item-text {
  font-weight: 400;
}
.left-menu .contact {
  width: 100%;
  padding-bottom: 1.5rem;
}
.left-menu .contact-text {
  color: $textWhite;
  text-align: center;
  opacity: 0.8;
}

/* Body Inner - Content */
.body-inner {
  float: right;
  width: 100%;
  padding: 1.5rem 0 0;
}
.body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 67px - 1.6rem);
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.body-scroll::-webkit-scrollbar { 
  width: 0; 
}
.no-breadcrumb .body-scroll {
  height: calc(100% - 28px - 2.5rem);
}
.body-title {
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  padding: 0 2rem;
  font-size: 24px;
  font-weight: 900;
  text-align: left;
}
.no-breadcrumb .body-title {
  margin-bottom: 1.5rem;
}
.body-title span {
  color: $darkGrayColor;
  font-size: 18px;
}

/* Body Info Blocks */
.links-block {
  flex-grow: 1;
  width: 100%;
  padding-bottom: 2rem;
  border-radius: 25px;
  background-color: $grayColor;
}
.info-block {
  margin-top: 2rem;
  padding: 0 2rem;
}
.info-block .title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-block .link-title-wrap .info-block-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
}
.info-block .info-block-title {
  font-size: 14px;
  font-weight: 600;
}
.info-block .info-block-num {
  font-size: 18px;
  font-weight: 900;
}
.info-block .link-title-wrap .info-block-num {
  margin-top: 2rem;
}
.info-block .info-block-num span {
  font-size: 14px;
}
.info-block .info-block-num a {
  color: $blueColor;
  font-size: 14px;
  font-weight: 400;
}

.tabs-list {
  flex-grow: 0;
  width: 100%;
  padding: 1.75rem 2rem 2rem;
}
.btn-list {
  flex-grow: 0;
  width: 100%;
  padding: 0 0 2rem 0;
}

/* Cards List */
.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: -10px;
  padding-bottom: 10px;
}
.card-list-scroll {
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-right: 0;
}
.card-list-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $grayColor;
  border-radius: 4px;
}
.card-list-scroll::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $blueColor;
}
.card-list-lines {
  align-items: stretch;
  flex-wrap: wrap;
}

/*=====================
    Pages Style
=====================*/

/* Login Style */
.login-content {
  height: calc(100% - 90px);
}
.login-content .row {
  height: 100%;
}
.login-content .col-sm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

/* Control Panel */
.m-top-0 {
  margin-top: 0 !important;
}
.home-plan-wrap {
  position: relative;
  display: block;
  height: 200px;
  margin-bottom: 2rem;
  background-color: $bgWhite;
  overflow: hidden;
  cursor: pointer;
}
.home-plan {
  width: 100%;
}
.home-plan-shadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(103, 97, 90, 0.1);
}
.home-plan-shadow:hover {
  background-color: rgba(103, 97, 90, 0.65);
}
.home-plan-shadow p {
  color: $textWhite;
  font-weight: 600;
  opacity: 0;
}
.home-plan-shadow:hover p {
  opacity: 1;
}

/* Device Page Style */
.device-settings {
  padding: 0 2rem;
  margin-bottom: 24px;
}


/*=====================
    Cards Style
=====================*/

/* Info Card */
.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.info-card .info-card-icon {
  width: 24px;
  height: 24px;
  filter: invert(1);
}
.info-card .info-card-title {
  font-size: 22px;
  line-height: 24px;
}
.info-card .info-card-title span {
  font-size: 16px;
  vertical-align: top;
}
.info-card .info-card-text {
  font-size: 13px;
}

/* Info Left Image Card */
.info-img-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}
.info-img-card-icon {
  width: 38px;
  height: 38px;
  margin-right: 20px;
}
.info-img-card-wrap {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.info-img-card-title {
  font-size: 22px;
  text-align: left;
}
.info-img-card-title span {
  font-size: 16px;
}
.info-img-card-text {
  text-align: left;
}

/* Device Card */
.device-card {
  display: inline-flex;
  position: relative;
  width: 235px;
  margin-right: 10px;
  margin-bottom: 1rem;
  padding: 1rem;
}
.device-card:hover, 
.device-card:active, 
.device-card:focus {
  background-color: $bgYellow;
}
.device-card-icon-wrap {
  position: relative;
  width: 32px;
  height: 32px;
}
.device-card .device-card-icon {
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  opacity: 1;
}
.device-card .device-card-icon-small {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 3px;

}
.device-card:hover .device-card-icon, 
.device-card:active .device-card-icon, 
.device-card:focus .device-card-icon {
  opacity: 0;
}
.device-card .device-card-icon-active {
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  opacity: 0;
}
.device-card:hover .device-card-icon-active, 
.device-card:active .device-card-icon-active, 
.device-card:focus .device-card-icon-active {
  opacity: 1;
}
.device-card .device-card-title {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  color: $textBlack;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.device-card:hover .device-card-title, 
.device-card:active .device-card-title, 
.device-card:focus .device-card-title {
  color: $textWhite;
}
.device-card .device-card-text {
  color: $darkGrayColor;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
}
.device-card .device-card-text span {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
.device-card:hover .device-card-text, 
.device-card:active .device-card-text, 
.device-card:focus .device-card-text {
  color: $textWhite;
}
.device-card .status-wrap {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-width: 0;
  background-color: transparent;
}
.device-card .status-wrap:hover + .select-bar {
  display: flex;
}
.device-card .status-wrap:focus {
  outline: 0;
}
.device-card .select-bar {
  display: none;
  position: absolute;
  top: 56px;
  right: 0;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 210px;
  padding: 8px 0;
  border-radius: 8px;
  border: 1px solid $darkGrayColor;
  box-shadow: 0 2px 4px rgba(103, 97, 90, 0.1);
  background-color: $whiteColor;
}
.device-card .select-bar:hover {
  display: flex;
}
.device-card .select-bar .select-bar-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px;
  border-width: 0;
  background-color: transparent;
  color: $blackColor;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}
.device-card .select-bar-btn img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.device-card .select-bar .select-bar-btn:focus {
  outline: 0;
}
.device-card .select-bar .select-bar-btn:hover {
  background-color: $grayColor;
}
.device-card .status-icon {
  width: 6px;
  height: 6px;
  margin-top: 3px;
  border-radius: 3px;
}
.device-card .status-icon.on {
  background-color: $iconYellow;
}
.device-card .status-icon.off {
  background-color: $iconGray;
}
.device-card:hover .status-icon.off, 
.device-card:active .status-icon.off, 
.device-card:focus .status-icon.off {
  background-color: $iconWhite;
}

/* Room Card */
.room-card {
  width: 235px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}
.room-card:hover {
  background-color: $bgYellow;
}
.room-card-img-wrap {
  position: relative;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
}
.room-card-img {
  width: 100%;
}
.room-card-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.room-card:hover .room-card-shadow {
  background-color: $bgYellow;
  opacity: 0.25;
}
.room-card-title {
  padding: 0.6rem 1rem 0 1rem;
  color: $textBlack;
  font-size: 16px;
  font-weight: 600;
}
.room-card-text {
  padding: 0 1rem 0.8rem 1rem;
  color: $textDark;
}
.room-card:hover .room-card-title {
  color: $textWhite;
}
.room-card:hover .room-card-text {
  color: $textWhite;
}

/* Script Small Card */
.script-small-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 116px;
  max-width: 180px;
  margin: 0.5rem 10px 0 0;
  padding: 1.2rem;
  background-color: $grayColor;
  cursor: pointer;
}
.script-small-card.active {
  background: $gradBlueStart; /* Old browsers */
  background: -moz-linear-gradient(top, $gradBlueStart 0%, $gradBlueStart 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $gradBlueStart 0%, $gradBlueStart 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $gradBlueStart 0%, $gradBlueStart 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$gradBlueStart, endColorstr=$gradBlueStart,GradientType=0 );
}
.script-small-card-icon {
  width: 32px;
  height: 32px;
}
.script-small-card-title {
  margin-top: 0.2rem;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.script-small-card.active .script-small-card-title {
  color: $textWhite;
}

/* Script Card */
.script-card {
  width: 235px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 1.2rem 6px 1.2rem 1rem;
  flex-direction: row;
}
.script-card:hover {
  background-color: $bgYellow;
}
.script-card-top {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.script-card-info {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.top-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.script-card-img-wrap {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.script-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  opacity: 1;
}
.script-card-img.hover {
  opacity: 0;
}
.script-card:hover .script-card-img {
  opacity: 0;
}
.script-card:hover .script-card-img.hover {
  opacity: 1;
}
.script-card-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.script-card:hover .script-card-title {
  color: $textWhite;
}
.script-card-controls {
  flex-grow: 0;
  flex-shrink: 0;
  width: 36px;
}
.script-info-text {
  color: $textDark;
  font-size: 12px;
  line-height: 14px;
}
.script-card:hover .script-info-text {
  color: $textWhite;
}

.script-card-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 28px;
}
.script-card-btn-icon {
  position: absolute;
  width: 18px;
  height: 18px;
}
.script-card-btn-icon.hover {
  opacity: 0;
}
.script-card:hover .script-card-btn-icon:first-child {
  opacity: 0;
}
.script-card:hover .script-card-btn-icon.hover {
  opacity: 1;
}

/* Statistic Card */
.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
}
.stat-card-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stat-card-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.stat-card-title {
  font-size: 20px;
  font-weight: 600;
}
.stat-card-back {
  width: 12px;
  height: 12px;
}
.stat-card-text {
  color: $textDark;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

/*========================
    Editor Rooms Plan
========================*/
.rooms-plan-wrap {
  width: 100%;
  padding: 0 2rem 2rem;
}
.rooms-plan-wrap.open {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: rgba(103, 97, 90, 0.25);
}
.rooms-plan {
  border-radius: 25px;
  background-color: $bgLight;
  overflow: hidden;
  box-shadow: 0 3px 4px rgba(103, 97, 90, 0.25);
}
.rooms-plan-wrap.open .rooms-plan {
  width: 100%;
  height: 100%;
}
.layout-control {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem 0 2rem;
}
.contentStage {
  width: 100%;
  height: 626px;
  padding: 1rem;
  overflow: hidden;
}
#stage {
  background-color: $bgWhite;
}
.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  width: 320px;
  height: 673px;
  padding: 0 1rem;
  background-color: $bgLight;
  box-shadow: -2px 0 4px rgba(103,97,90,0.15);
  overflow-y: auto;
}
.control-panel:hover {
  transition: height 0s ease 0s;
}
.control-panel::-webkit-scrollbar {
  width: 8px;
  background-color: $whiteColor;
  border-radius: 4px;
}
.control-panel::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 4px;
  background-color: $blueColor;
}
.control-panel.hide {
  overflow: hidden;
  width: 130px;
  height: 48px;
  padding: 0 1rem 0 0;
  box-shadow: none;
  background-color: transparent;
}
.panel-buttons {
  padding-bottom: 16px;
}
.control-panel.hide .panel-buttons {
  opacity: 0;
}
.rooms-plan-wrap.open .control-panel {
  height: 100%;
}
.control-panel-hide {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0 0.4rem 12px;
}
.control-panel.hide .control-panel-hide {
  padding: 1rem 0 1rem 12px;
}
.hide-btn {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  margin-right: 8px;
}
.control-panel-title {
  margin-bottom: 0.4rem;
  font-size: 14px;
  font-weight: 600;
}
.device-btn {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  margin-right: 8px;
}

.empty-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-self: start;
}
.empty-list img {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 16px;
}
.empty-list .empty-list-info {
  flex-grow: 1;
  flex-shrink: 1;
}
.empty-list .empty-list-info h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

/*===========================
  Mail Chat Page
  ===========================*/
.mail-list {
  width: 100%;
  padding: 1rem 2rem;
}
.mail-list .list-title {
  position: relative;
  margin: 24px 0 8px 0;
  padding-left: 16px;
  color: $darkGrayColor;
  font-size: 18px;
  font-weight: 600;
}
.mail-list .list-title:before {
  content: '';
  position: absolute;
  left: 2px;
  top: calc(50% - 3px);
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: $darkGrayColor;
}
.mail-list .title-new {
  color: $blueColor;
}
.mail-list .list-title.title-new:before {
  background-color: $blueColor;
}
.mail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  margin-top: -1px;
  border-top: 1px solid $grayColor;
  border-bottom: 1px solid $grayColor;
  cursor: pointer;
}
.mail-item:hover {
  background-color: $grayColor;
}
.mail-item h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.mail-item p {
  margin: 0;
  color: $darkGrayColor;
  font-size: 12px;
}

.mail-chat {
  height: 100%;
  padding: 1rem 2rem;
}
.mail-chat h3 {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}
.mail-chat .mail-chat-form {
  margin-bottom: 8px;
}
.mail-chat-msg {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mail-chat-msg .msg {
  align-self: flex-end;
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid $grayColor;
  border-radius: 8px;
  background-color: $grayColor;
}
.mail-chat-msg .msg.msg-user {
  align-self: flex-start;
  background-color: $whiteColor;
}
.mail-chat-msg .msg .msg-text {
  text-align: right;
}
.mail-chat-msg .msg.msg-user .msg-text {
  text-align: left;
}
.mail-chat-msg .msg .msg-info {
  color: $darkGrayColor;
  font-size: 12px;
  text-align: right;
}
.mail-chat-msg .msg.msg-user .msg-info {
  text-align: left;
}


.table-wrap {
  width: 100%;
  padding: 1rem 2rem;
}
.table-scroll-wrap {
  overflow-x: scroll;
}
.table-scroll-wrap::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $grayColor;
  border-radius: 4px;
}
.table-scroll-wrap::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $blueColor;
}

.notification-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 8px;
}
.notification-text-wrap {
  display: flex;
  align-items: center;
}
.notification-card-title {
  margin-right: 8px;
  color: $blackColor;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.notification-card-text {
  color: $blackColor;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
.notification-card-time {
  margin-left: 20px;
  color: $darkGrayColor;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
}
.notification-icon-wrap {
  width: 20px;
  height: 28px;
  margin-right: 8px;
}
.notification-icon-wrap img {
  width: 20px;
  height: 20px;
}
.notification-icon-wrap .notification-icon-active {
  display: none;
}

/*========================
    Mobile Site Version 
========================*/
@media all and ( max-width: 1199px ) {
  .menu-fixed {
    left: calc((100% - 960px + 30px)/2);
  }
  .room-card {
    width: 268px;
  }
  .device-card {
    width: 268px;
  }
}
@media all and ( min-width: 991px) {
  .menu-fixed {
    height: 100%;
  }
  .left-menu .left-menu-control .btn-icon {
    opacity: 0;
  }
}
@media all and ( max-width: 991px ) {
  .menu-fixed {
    left: calc((100% - 720px + 30px)/2);
  }
  .content-card {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  .card-body .left-menu {
    position: absolute;
    left: -20px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden;
  }
  .card-body .body-inner {
    width: 100%;
  }
  .menu-fixed {
    left: calc((100% - 720px)/2);
  }
  .left-menu {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  .left-menu.active {
    width: 300px;
    height: 100%;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .left-menu .left-menu-control .btn-icon {
    padding: 16px 19px 18px 20px;
  }
  .card-body .left-menu.active {
    width: 300px;
    height: calc(100% + 20px);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 9;
  }
  .card-body .left-menu:hover {
    width: 300px;
    height: calc(100% + 20px);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 9;
  }
  .room-card {
    width: 30%;
  }
  .device-card {
    width: 308px;
  }
}
@media all and (max-width: 768px) {
  .menu-fixed {
    left: calc((100% - 540px)/2);
  }
  .device-card {
    width: 100%;
  }
}
@media all and (max-width: 575px) {
  .menu-fixed {
    left: 0;
  }
}
@media all and ( max-width: 480px ) {
  .menu-fixed {
    width: 100%;
    padding-top: 70px;
  }
  .left-menu {
    width: 100%;
    height: 80px;
    padding-top: 20px;
    border-radius: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .left-menu .profile-wrap,  
  .left-menu .menu-list,
  .left-menu .contact   {
    display: none;
  }
  .left-menu.active {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .left-menu.active .profile-wrap {
    display: flex;
  }
  .left-menu.active .menu-list,
  .left-menu.active .contact {
    display: block;
  }
  .header .logo-text img {
    height: 32px;
  }
  .header .logo-wrap {
    width: 48px;
    height: 48px;
  }
  .header .logo-wrap img {
    width: 40px;
    height: 40px;
  }
  .header h1 {
    font-size: 22px;
  }
  .header h5 {
    font-size: 12px;
  }
}